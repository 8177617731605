<script setup>
import { ref, computed, watch } from 'vue';

import { useBatchedCompletion } from 'JIX/utils/completion.js';
import SearchField from 'JIX/components/SearchField.vue';
import CompanyRatingStars from 'JIX/components/CompanyRatingStars.vue';

import { __, __nx } from 'JIX/gettext.js';

const props = defineProps({
    value: {
        type: String,
        default: "",
    },
    size: {
        type: String,
        default: 'normal',
        validator: (val) => ['normal', 'large'].includes(val),
    },
    tab: {
        type: String,
        default: "about",
    },
    suggestionUrl: {
        type: String,
        default: "/api/suggestions/company/public",
    },
    completionDelay: {
        type: Number,
        default: 500,
    },
});

// Instance data
const query = ref(props.value);
const navigating = ref(false);

const queryUrl = computed(() => {
    if (!query.value) {
        return null;
    }
    const params = [ ['company', query.value], ['use_ad_search', 1], ['tab', props.tab] ];
    return `${props.suggestionUrl}?${new URLSearchParams(params)}`;
});
const {
    searching,
    completions,
    requestCompletionsUpdate,
} = useBatchedCompletion({
    query: queryUrl,
    delay: props.completionDelay,
});

watch(query, () => !navigating.value && requestCompletionsUpdate(), { immediate: true });

function goToProfile(event, index) {
    if (index < 0) {
        event.target.form.submit();
        navigating.value = true;
    } else {
        const selected = completions.value[index];
        if (selected) {
            query.value = selected.text;
            window.location = selected.url;
            navigating.value = true;
        }
    }
}

</script>

<template>
    <SearchField
        root-class="companyindex-search"
        :class="{ 'form-control-lg': size === 'large' }"
        v-model="query"
        :completions="completions"
        :searching="searching"
        :placeholder="__('Søg efter virksomhed')"
        @confirm="goToProfile"
        v-slot="{ item }"
    >
        <div class="d-block d-md-inline companyindex-search__companyname align-true-center">
            {{ item.text }}
        </div>
        <div class="text-xs text-muted d-md-inline align-true-center">
            <CompanyRatingStars
                v-if="item.rating"
                :rating="item.rating"
                size="inline"
                class="ml-md-2"
            />
            <span
                v-if="item.hits"
            >
                <span
                    :class="{ 'mx-1': true, 'd-none': !item.rating, 'd-md-inline': !item.rating }"
                >
                    &bull;
                </span>
                {{ __nx('{n} job', '{n} job', item.hits, { n: item.hits }) }}
            </span>
        </div>
        <span
            v-if="item.logo"
            v-html="item.logo"
            class="float-right companyindex-search__logo d-none d-md-inline"
        />
    </SearchField>
</template>
