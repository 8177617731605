<script setup>
import T from './CompanyRatingStars.i18n.js';

import { computed } from 'vue';
import SvgIcon from 'JIX/components/SvgIcon.vue';

import 'icons/rating-selected.svg';
import 'icons/rating-unselected.svg';

const emit = defineEmits(['selected']);

const props = defineProps({
    rating: {
        type: Number,
        required: true,
        validator: (x) => x >= 0,
    },
    maxRating: {
        type: Number,
        required: false,
        default: 5,
        validator: (x) => x > 0,
    },
    size: {
        type: String,
        required: false,
        default: 'md',
        validator: (x) => ['inline', 'xs', 'sm', 'md', 'lg'].includes(x),
    },
    interactive: {
        type: Boolean,
        required: false,
        default: false,
    },
});

const iconName = computed(() => props.rating || !props.interactive ? 'rating-selected' : 'rating-unselected');

function getClasses(index) {
    return [
        'jix-company-rating__star',
        {
            'jix-company-rating__star--unfilled': !props.rating && props.interactive,
            'jix-company-rating__star--selected': index <= props.rating,
        },
    ];
}
</script>

<template>
    <div :class="['jix-company-rating', 'jix-company-rating--' + size]">
        <template v-if="interactive">
            <button
                type="button"
                :class="['jix-company-rating__button', 'jix-company-rating__button--' + size]"
                v-for="i in maxRating"
                :key="i"
                :title="T.stars(i)"
                @click.prevent="emit('selected', { rating: i })"
            >
                <SvgIcon
                    :name="iconName"
                    :size="size"
                    :class="getClasses(i)"
                />
            </button>
        </template>
        <template v-else>
            <span class="sr-only">{{ rating ? T.ratingText(rating, maxRating) : T.noRating() }}</span>
            <SvgIcon
                :name="iconName"
                :size="size"
                :class="[...getClasses(i), 'jix-company-rating__star--' + size]"
                v-for="i in maxRating"
                :key="i"
                role="presentation"
            />
        </template>
    </div>
</template>
