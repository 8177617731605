import { createApp } from 'vue';

import SearchField from 'JIX/components/CompanyIndexSearchField.vue';

const stash = window.Stash['companyprofile_searchbox'];

const props = {
    name: 'company',
    value: stash.query || "",
    size: stash.isFrontpage ? "large" : "normal",
    tab: stash.evaluateTab ? "evaluations" : "about",
};

const app = createApp(SearchField, props);
app.mount('#company');
