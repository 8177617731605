import { __, __x, __nx, __N } from 'JIX/gettext.js';

export default {
    ratingText: (n, max) =>
        __x('{n} af {max} stjerner', { n, max }),
    stars: (n) =>
        __nx('{n} stjerne', '{n} stjerner', n, { n }),
    noRating: () =>
        __('Ingen evalueringer.'),
};
